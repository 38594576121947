import { defineStore } from "pinia";
import { ref, computed } from "@nuxtjs/composition-api";
import { useCookieManager } from "./composables/useCookieManager";
import { useCheckMismatch } from "./composables/useCheckMismatch";
import { useUserStore } from "~/stores/user";

/**
 * Store that can work both client and server side.
 */
export const useAuthStore = defineStore(
  "useAuthStore",
  () => {
    const userToken = ref(null);
    const adminToken = ref(null);

    let composablesInitialized = false;
    let cookieManager = null;
    let userStore = null;
    let checkMismatch = null;

    if (process.client) {
      userStore = useUserStore();
      cookieManager = useCookieManager();
      checkMismatch = useCheckMismatch();
      composablesInitialized = true;
    }

    // Required for SSR as useContext will fail on server side
    const initComposables = (context) => {
      if (composablesInitialized) return;
      userStore = useUserStore(context?.$pinia);
      cookieManager = useCookieManager(context);
      checkMismatch = useCheckMismatch(context);
      composablesInitialized = true;
    };

    function setToken(token, context) {
      initComposables(context);
      userToken.value = token;
      cookieManager.setTokenCookie(token, "user");
    }

    const isAuthenticated = computed(() => {
      return !!userToken.value;
    });

    function setAdminToken(token, context) {
      initComposables(context);
      adminToken.value = token;
      cookieManager.setTokenCookie(token, "admin");
    }

    function logout(context) {
      initComposables(context);
      cookieManager.clearTokenCookieForAll();
      userToken.value = null;
      adminToken.value = null;
      userStore.$reset();

      // TODO find a better place for this
      this.$nuxt.$sentry.setUser(null);
    }

    async function loginWithToken(token, context) {
      initComposables(context);

      setToken(token);

      await userStore.queryMe();

      // TODO find a better place for this
      this.$nuxt.$sentry.setUser({
        id: userStore.me.id,
        email: userStore.me.email,
      });

      checkMismatch(userStore.me.id, userToken.value);

      if (userStore.me.isAdmin) {
        setAdminToken(userToken.value);
      }

      cookieManager.clearCampaignsCookie();
    }

    return {
      userToken,
      adminToken,
      setAdminToken,
      setToken,
      loginWithToken,
      logout,
      isAuthenticated,
    };
  },
  {}
);
