import {
  required,
  requiredIf,
  minValue,
  maxValue,
  minLength,
  maxLength,
  sameAs,
  email,
  integer,
  createI18nMessage,
  helpers,
} from "@vuelidate/validators";
import { validatePassword } from "~/helpers/password";

const numericMask = (mask) => {
  const maskReadable = mask.replace(/#/g, (_, offset) => (offset + 1) % 10);
  const maskRegex = new RegExp(mask.replace(/#/g, "\\d"));

  return helpers.withParams(
    { mask: maskReadable, type: "numericMask" },
    helpers.regex(maskRegex)
  );
};

export const consentRequired = (message) =>
  helpers.withParams(
    { message, type: "consentRequired" },
    (value) => value === true
  );

export const passwordFormat = (options = {}) => ({
  $params: { type: "passwordFormat", ...options },
  $validator(value) {
    const result = validatePassword(value, options);
    return {
      $valid: result.valid,
      errorName: result.errorName,
      extraParams: result.params,
    };
  },
});

export default function ({ app }, inject) {
  const withI18nMessage = createI18nMessage({ t: app.i18n.t.bind(app.i18n) });

  inject("validation", {
    rules: {
      required: withI18nMessage(required),
      requiredIf: withI18nMessage(requiredIf, { withArguments: true }), // todo check
      minPrice: (min) =>
        helpers.withMessage(
          app.i18n.t("validations.minPrice", {
            minPrice: app.i18n.n(min, "currency"),
          }),
          minValue(min)
        ),
      maxPrice: (max) =>
        helpers.withMessage(
          app.i18n.t("validations.maxPrice", {
            maxPrice: app.i18n.n(max, "currency"),
          }),
          maxValue(max)
        ),
      minLength: withI18nMessage(minLength, { withArguments: true }),
      maxLength: withI18nMessage(maxLength, { withArguments: true }),
      minValue: withI18nMessage(minValue, { withArguments: true }),
      maxValue: withI18nMessage(maxValue, { withArguments: true }),
      email: withI18nMessage(email),
      integer: withI18nMessage(integer),
      mustBeSlug: withI18nMessage(helpers.regex(/^[a-z\d-]*$/)),
      numericMask: withI18nMessage(numericMask, {
        withArguments: true,
      }),
      consentRequired: withI18nMessage(consentRequired, {
        withArguments: true,
      }),
      passwordFormat: withI18nMessage(passwordFormat, {
        withArguments: true,
        messagePath: ({ $params, $response }) =>
          `validations.${$params.type}.${$response.errorName}`,
        messageParams: (params) => {
          return { ...params, ...params.response.extraParams };
        },
      }),
      sameAsPassword: withI18nMessage(sameAs, { withArguments: true }),
    },
    withI18nMessage,
  });
}
