import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5a727380 = () => interopDefault(import('../pages/request-for-tender/index.vue' /* webpackChunkName: "pages/request-for-tender/index" */))
const _5751fb74 = () => interopDefault(import('../pages/customer-registration/index.vue' /* webpackChunkName: "pages/customer-registration/index" */))
const _71fcfa18 = () => interopDefault(import('../pages/prices/index.vue' /* webpackChunkName: "pages/prices/index" */))
const _0a6a041a = () => interopDefault(import('../pages/terms/index.vue' /* webpackChunkName: "pages/terms/index" */))
const _908b9d56 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _48caa40c = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _1d4b0d3b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6495c202 = () => interopDefault(import('../pages/elso-belepes/index.vue' /* webpackChunkName: "pages/elso-belepes/index" */))
const _2d173a0a = () => interopDefault(import('../pages/email-confirm/index.vue' /* webpackChunkName: "pages/email-confirm/index" */))
const _2a3e2504 = () => interopDefault(import('../pages/health-check.vue' /* webpackChunkName: "pages/health-check" */))
const _fb72cc32 = () => interopDefault(import('../pages/heroku-login.vue' /* webpackChunkName: "pages/heroku-login" */))
const _47206ed6 = () => interopDefault(import('../pages/inspiration/index.vue' /* webpackChunkName: "pages/inspiration/index" */))
const _00ad33c0 = () => interopDefault(import('../pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _511ad2d2 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _0b624f82 = () => interopDefault(import('../pages/koszonjuk/index.vue' /* webpackChunkName: "pages/koszonjuk/index" */))
const _733c9bff = () => interopDefault(import('../pages/leiratkozas/index.vue' /* webpackChunkName: "pages/leiratkozas/index" */))
const _dd5630b6 = () => interopDefault(import('../pages/job-notification/index.vue' /* webpackChunkName: "pages/job-notification/index" */))
const _61aa34d3 = () => interopDefault(import('../pages/no-password/index.vue' /* webpackChunkName: "pages/no-password/index" */))
const _184d40c2 = () => interopDefault(import('../pages/obi.vue' /* webpackChunkName: "pages/obi" */))
const _b95be240 = () => interopDefault(import('../pages/registration.vue' /* webpackChunkName: "pages/registration" */))
const _735f8a6a = () => interopDefault(import('../pages/registration/professions/index.vue' /* webpackChunkName: "pages/registration/professions/index" */))
const _524fed63 = () => interopDefault(import('../pages/registration/index.vue' /* webpackChunkName: "pages/registration/index" */))
const _a1700f48 = () => interopDefault(import('../pages/registration/introduction/index.vue' /* webpackChunkName: "pages/registration/introduction/index" */))
const _500d6820 = () => interopDefault(import('../pages/registration/email/index.vue' /* webpackChunkName: "pages/registration/email/index" */))
const _ad4c4dc6 = () => interopDefault(import('../pages/registration/password/index.vue' /* webpackChunkName: "pages/registration/password/index" */))
const _b5fd23a2 = () => interopDefault(import('../pages/registration/jobfeed/index.vue' /* webpackChunkName: "pages/registration/jobfeed/index" */))
const _35ab188d = () => interopDefault(import('../pages/registration/name/index.vue' /* webpackChunkName: "pages/registration/name/index" */))
const _56af3edc = () => interopDefault(import('../pages/registration/picture/index.vue' /* webpackChunkName: "pages/registration/picture/index" */))
const _c475fd52 = () => interopDefault(import('../pages/registration/success/index.vue' /* webpackChunkName: "pages/registration/success/index" */))
const _af78cffe = () => interopDefault(import('../pages/registration/invoice/index.vue' /* webpackChunkName: "pages/registration/invoice/index" */))
const _6a739f42 = () => interopDefault(import('../pages/registration/phone/index.vue' /* webpackChunkName: "pages/registration/phone/index" */))
const _6615c126 = () => interopDefault(import('../pages/registration/city/index.vue' /* webpackChunkName: "pages/registration/city/index" */))
const _175a9af0 = () => interopDefault(import('../pages/registration/_.vue' /* webpackChunkName: "pages/registration/_" */))
const _31ae2552 = () => interopDefault(import('../pages/salaries/index.vue' /* webpackChunkName: "pages/salaries/index" */))
const _336443bc = () => interopDefault(import('../pages/szakifizu/index.vue' /* webpackChunkName: "pages/szakifizu/index" */))
const _565205b9 = () => interopDefault(import('../pages/questions/index.vue' /* webpackChunkName: "pages/questions/index" */))
const _317cd4b4 = () => interopDefault(import('../pages/profession/index.vue' /* webpackChunkName: "pages/profession/index" */))
const _3dcf3a3e = () => interopDefault(import('../pages/szerzodesmintak/index.vue' /* webpackChunkName: "pages/szerzodesmintak/index" */))
const _1709b8b2 = () => interopDefault(import('../pages/profession-tasks/index.vue' /* webpackChunkName: "pages/profession-tasks/index" */))
const _2c31a3dc = () => interopDefault(import('../pages/new-password/index.vue' /* webpackChunkName: "pages/new-password/index" */))
const _06fc26ab = () => interopDefault(import('../pages/new-professionals/index.vue' /* webpackChunkName: "pages/new-professionals/index" */))
const _6e9a3916 = () => interopDefault(import('../pages/velemeny/index.vue' /* webpackChunkName: "pages/velemeny/index" */))
const _4406f213 = () => interopDefault(import('../pages/conductor/index.vue' /* webpackChunkName: "pages/conductor/index" */))
const _b6d5b74a = () => interopDefault(import('../pages/visszajelzes/index.vue' /* webpackChunkName: "pages/visszajelzes/index" */))
const _7aa3a8ef = () => interopDefault(import('../pages/components/buttons.vue' /* webpackChunkName: "pages/components/buttons" */))
const _20d5d42a = () => interopDefault(import('../pages/components/inputs.vue' /* webpackChunkName: "pages/components/inputs" */))
const _4b5bd1ee = () => interopDefault(import('../pages/components/misc.vue' /* webpackChunkName: "pages/components/misc" */))
const _62aad9cd = () => interopDefault(import('../pages/my-account/my-tenders/index.vue' /* webpackChunkName: "pages/my-account/my-tenders/index" */))
const _3c131fce = () => interopDefault(import('../pages/my-account/prices/index.vue' /* webpackChunkName: "pages/my-account/prices/index" */))
const _6e41ee2c = () => interopDefault(import('../pages/my-account/subscription/index.vue' /* webpackChunkName: "pages/my-account/subscription/index" */))
const _64fb9938 = () => interopDefault(import('../pages/my-account/my-ratings/index.vue' /* webpackChunkName: "pages/my-account/my-ratings/index" */))
const _8d87a6a6 = () => interopDefault(import('../pages/my-account/jobs-in-progress/index.vue' /* webpackChunkName: "pages/my-account/jobs-in-progress/index" */))
const _d066ed6a = () => interopDefault(import('../pages/my-account/obtainable-jobs/index.vue' /* webpackChunkName: "pages/my-account/obtainable-jobs/index" */))
const _81489a52 = () => interopDefault(import('../pages/my-account/references/index.vue' /* webpackChunkName: "pages/my-account/references/index" */))
const _77b7d248 = () => interopDefault(import('../pages/fizetes/folyamatban.vue' /* webpackChunkName: "pages/fizetes/folyamatban" */))
const _c4e37d84 = () => interopDefault(import('../pages/fizetes/sikeres.vue' /* webpackChunkName: "pages/fizetes/sikeres" */))
const _bd5b12b8 = () => interopDefault(import('../pages/fizetes/sikertelen.vue' /* webpackChunkName: "pages/fizetes/sikertelen" */))
const _ede13eea = () => interopDefault(import('../pages/static/about-us/index.vue' /* webpackChunkName: "pages/static/about-us/index" */))
const _7a147332 = () => interopDefault(import('../pages/szakifizu/fizuk.js' /* webpackChunkName: "pages/szakifizu/fizuk" */))

const _4a2811df = () => interopDefault(import('../pages/questions/new-question/index.vue' /* webpackChunkName: "pages/questions/new-question/index" */))
const _d6bb450e = () => interopDefault(import('../pages/velemeny/koszonjuk.vue' /* webpackChunkName: "pages/velemeny/koszonjuk" */))
const _6a86b802 = () => interopDefault(import('../pages/conductor/persons/index.vue' /* webpackChunkName: "pages/conductor/persons/index" */))
const _5388a418 = () => interopDefault(import('../pages/conductor/newsletter-subscriptions/index.vue' /* webpackChunkName: "pages/conductor/newsletter-subscriptions/index" */))
const _b61144ca = () => interopDefault(import('../pages/conductor/inspirations/index.vue' /* webpackChunkName: "pages/conductor/inspirations/index" */))
const _411086de = () => interopDefault(import('../pages/conductor/questions/index.vue' /* webpackChunkName: "pages/conductor/questions/index" */))
const _31a3dc09 = () => interopDefault(import('../pages/conductor/profession-groups/index.vue' /* webpackChunkName: "pages/conductor/profession-groups/index" */))
const _3894910a = () => interopDefault(import('../pages/conductor/professions/index.vue' /* webpackChunkName: "pages/conductor/professions/index" */))
const _420dbb5f = () => interopDefault(import('../pages/visszajelzes/koszonjuk.vue' /* webpackChunkName: "pages/visszajelzes/koszonjuk" */))
const _588fe78e = () => interopDefault(import('../pages/tema/sos/festes.vue' /* webpackChunkName: "pages/tema/sos/festes" */))
const _661c0f0a = () => interopDefault(import('../pages/tema/sos/koszonjuk.vue' /* webpackChunkName: "pages/tema/sos/koszonjuk" */))
const _45db412a = () => interopDefault(import('../pages/conductor/tenders/judge/index.vue' /* webpackChunkName: "pages/conductor/tenders/judge/index" */))
const _7085908e = () => interopDefault(import('../pages/conductor/blog/articles/index.vue' /* webpackChunkName: "pages/conductor/blog/articles/index" */))
const _8ef50b2c = () => interopDefault(import('../pages/conductor/blog/tags/index.vue' /* webpackChunkName: "pages/conductor/blog/tags/index" */))
const _18885b3a = () => interopDefault(import('../pages/conductor/blog/collections/index.vue' /* webpackChunkName: "pages/conductor/blog/collections/index" */))
const _4e4d78c6 = () => interopDefault(import('../pages/conductor/inspirations/create/index.vue' /* webpackChunkName: "pages/conductor/inspirations/create/index" */))
const _420efad0 = () => interopDefault(import('../pages/conductor/profession-groups/create/index.vue' /* webpackChunkName: "pages/conductor/profession-groups/create/index" */))
const _691f7a34 = () => interopDefault(import('../pages/conductor/professions/create/index.vue' /* webpackChunkName: "pages/conductor/professions/create/index" */))
const _5efb2517 = () => interopDefault(import('../pages/conductor/blog/tags/create/index.vue' /* webpackChunkName: "pages/conductor/blog/tags/create/index" */))
const _c6fec31a = () => interopDefault(import('../pages/conductor/blog/articles/create/index.vue' /* webpackChunkName: "pages/conductor/blog/articles/create/index" */))
const _721e8b72 = () => interopDefault(import('../pages/conductor/blog/collections/create/index.vue' /* webpackChunkName: "pages/conductor/blog/collections/create/index" */))
const _11e7b479 = () => interopDefault(import('../pages/conductor/blog/articles/_id/index.vue' /* webpackChunkName: "pages/conductor/blog/articles/_id/index" */))
const _10ed4925 = () => interopDefault(import('../pages/conductor/blog/collections/_id/index.vue' /* webpackChunkName: "pages/conductor/blog/collections/_id/index" */))
const _21e75055 = () => interopDefault(import('../pages/conductor/blog/tags/_id/index.vue' /* webpackChunkName: "pages/conductor/blog/tags/_id/index" */))
const _4ca8bc10 = () => interopDefault(import('../pages/blog/professionTask/_professionTaskSeoName.vue' /* webpackChunkName: "pages/blog/professionTask/_professionTaskSeoName" */))
const _33530cd6 = () => interopDefault(import('../pages/blog/collection/_collectionSeoName.vue' /* webpackChunkName: "pages/blog/collection/_collectionSeoName" */))
const _25c6579a = () => interopDefault(import('../pages/blog/profession/_professionSeoName.vue' /* webpackChunkName: "pages/blog/profession/_professionSeoName" */))
const _a50d881c = () => interopDefault(import('../pages/blog/tag/_tagSeoName.vue' /* webpackChunkName: "pages/blog/tag/_tagSeoName" */))
const _213b99f4 = () => interopDefault(import('../pages/conductor/inspirations/_id/index.vue' /* webpackChunkName: "pages/conductor/inspirations/_id/index" */))
const _dcbec634 = () => interopDefault(import('../pages/conductor/professions/_id/index.vue' /* webpackChunkName: "pages/conductor/professions/_id/index" */))
const _3badca74 = () => interopDefault(import('../pages/conductor/profession-groups/_id/index.vue' /* webpackChunkName: "pages/conductor/profession-groups/_id/index" */))
const _67413166 = () => interopDefault(import('../pages/inspiration/profession-task/_professionTaskSeoName.vue' /* webpackChunkName: "pages/inspiration/profession-task/_professionTaskSeoName" */))
const _1ffae464 = () => interopDefault(import('../pages/inspiration/profession/_professionSeoName.vue' /* webpackChunkName: "pages/inspiration/profession/_professionSeoName" */))
const _533c1a7c = () => interopDefault(import('../pages/my-account/my-job/_personTenderId.vue' /* webpackChunkName: "pages/my-account/my-job/_personTenderId" */))
const _0b387cfc = () => interopDefault(import('../pages/my-account/settings/_highlightedSetting.vue' /* webpackChunkName: "pages/my-account/settings/_highlightedSetting" */))
const _5505892c = () => interopDefault(import('../pages/szakifizu/atlag/_szakmaSeo/index.vue' /* webpackChunkName: "pages/szakifizu/atlag/_szakmaSeo/index" */))
const _3979a7c4 = () => interopDefault(import('../pages/questions' /* webpackChunkName: "pages/questions" */))
const _4f6001ed = () => interopDefault(import('../pages/conductor/persons/_id/index.vue' /* webpackChunkName: "pages/conductor/persons/_id/index" */))
const _240e26fc = () => interopDefault(import('../pages/conductor/questions/_id/index.vue' /* webpackChunkName: "pages/conductor/questions/_id/index" */))
const _7420f8b0 = () => interopDefault(import('../pages/szakifizu/atlag/_szakmaSeo/munkak/index.vue' /* webpackChunkName: "pages/szakifizu/atlag/_szakmaSeo/munkak/index" */))
const _1a9412cc = () => interopDefault(import('../pages/arcsekkolo/_professionSeoName/index.vue' /* webpackChunkName: "pages/arcsekkolo/_professionSeoName/index" */))
const _759b63ea = () => interopDefault(import('../pages/blog/_articleSeoName.vue' /* webpackChunkName: "pages/blog/_articleSeoName" */))
const _8b1fad3c = () => interopDefault(import('../pages/prices/_professionTask.vue' /* webpackChunkName: "pages/prices/_professionTask" */))
const _62fd8224 = () => interopDefault(import('../pages/inspiration/_inspirationSeoName/index.vue' /* webpackChunkName: "pages/inspiration/_inspirationSeoName/index" */))
const _00df2ea8 = () => interopDefault(import('../pages/counties/_county/index.vue' /* webpackChunkName: "pages/counties/_county/index" */))
const _8f771e38 = () => interopDefault(import('../pages/professionals/_profession/index.vue' /* webpackChunkName: "pages/professionals/_profession/index" */))
const _40a8adb6 = () => interopDefault(import('../pages/professional/_professionalSeoName/index.vue' /* webpackChunkName: "pages/professional/_professionalSeoName/index" */))
const _1536bb6f = () => interopDefault(import('../pages/questions/_questionSeoName/index.vue' /* webpackChunkName: "pages/questions/_questionSeoName/index" */))
const _5981deac = () => interopDefault(import('../pages/partner-ajanlatkeres/_partnerCode.vue' /* webpackChunkName: "pages/partner-ajanlatkeres/_partnerCode" */))
const _ee6be1fa = () => interopDefault(import('../pages/profession/_professionGroup/index.vue' /* webpackChunkName: "pages/profession/_professionGroup/index" */))
const _58c66acc = () => interopDefault(import('../pages/projects/_id.vue' /* webpackChunkName: "pages/projects/_id" */))
const _c819e8c6 = () => interopDefault(import('../pages/tender/_tenderId/index.vue' /* webpackChunkName: "pages/tender/_tenderId/index" */))
const _a1b16392 = () => interopDefault(import('../pages/valassz/_decisionTestSeoName.vue' /* webpackChunkName: "pages/valassz/_decisionTestSeoName" */))
const _3e34b71c = () => interopDefault(import('../pages/request-for-tender/_activeTab.vue' /* webpackChunkName: "pages/request-for-tender/_activeTab" */))
const _d01b40a8 = () => interopDefault(import('../pages/professional/_professionalSeoName/rating/index.vue' /* webpackChunkName: "pages/professional/_professionalSeoName/rating/index" */))
const _502ba96f = () => interopDefault(import('../pages/tender/_tenderId/specify/index.vue' /* webpackChunkName: "pages/tender/_tenderId/specify/index" */))
const _23a89800 = () => interopDefault(import('../pages/professional/_professionalSeoName/admin/balance.vue' /* webpackChunkName: "pages/professional/_professionalSeoName/admin/balance" */))
const _08378e5c = () => interopDefault(import('../pages/professional/_professionalSeoName/admin/merge.vue' /* webpackChunkName: "pages/professional/_professionalSeoName/admin/merge" */))
const _006ec025 = () => interopDefault(import('../pages/professional/_professionalSeoName/admin/person-event-log.vue' /* webpackChunkName: "pages/professional/_professionalSeoName/admin/person-event-log" */))
const _269d9be8 = () => interopDefault(import('../pages/professional/_professionalSeoName/admin/transfers.vue' /* webpackChunkName: "pages/professional/_professionalSeoName/admin/transfers" */))
const _15c88c18 = () => interopDefault(import('../pages/professionals/_profession/szakivalaszol/_questionSeoName.vue' /* webpackChunkName: "pages/professionals/_profession/szakivalaszol/_questionSeoName" */))
const _c108683a = () => interopDefault(import('../pages/tender/_tenderId/specify/_activeTab.vue' /* webpackChunkName: "pages/tender/_tenderId/specify/_activeTab" */))
const _3ebeabe0 = () => interopDefault(import('../pages/arcsekkolo/_professionSeoName/_priceTagSeoName.vue' /* webpackChunkName: "pages/arcsekkolo/_professionSeoName/_priceTagSeoName" */))
const _4b7e0f5e = () => interopDefault(import('../pages/inspiration/_inspirationSeoName/_pictureSeoName/index.vue' /* webpackChunkName: "pages/inspiration/_inspirationSeoName/_pictureSeoName/index" */))
const _e9c1468c = () => interopDefault(import('../pages/price-calculator/_professionTask/_step.vue' /* webpackChunkName: "pages/price-calculator/_professionTask/_step" */))
const _cdddc348 = () => interopDefault(import('../pages/professionals/_profession/_city.vue' /* webpackChunkName: "pages/professionals/_profession/_city" */))
const _a8282a72 = () => interopDefault(import('../pages/counties/_county/_city/_subCity.vue' /* webpackChunkName: "pages/counties/_county/_city/_subCity" */))
const _2d222478 = () => interopDefault(import('../pages/professionals/_profession/_city' /* webpackChunkName: "pages/professionals/_profession/_parentCity/_city" */))
const _03cf66c8 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ajanlatkeres",
    component: _5a727380,
    name: "requestForTender___hu"
  }, {
    path: "/ajanlatkeroi-regisztracio",
    component: _5751fb74,
    name: "customerRegistrationPage___hu"
  }, {
    path: "/arak",
    component: _71fcfa18,
    name: "pricesPage___hu"
  }, {
    path: "/aszf",
    component: _0a6a041a,
    name: "terms___hu"
  }, {
    path: "/belepes",
    component: _908b9d56,
    name: "login___hu"
  }, {
    path: "/blog",
    component: _48caa40c,
    name: "blogLandingPage___hu"
  }, {
    path: "/cs",
    component: _1d4b0d3b,
    name: "indexPage___cs"
  }, {
    path: "/elso-belepes",
    component: _6495c202,
    name: "elso-belepes___hu"
  }, {
    path: "/email-megerosites",
    component: _2d173a0a,
    name: "emailConfirmPage___hu"
  }, {
    path: "/health-check",
    component: _2a3e2504,
    name: "health-check___hu"
  }, {
    path: "/heroku-login",
    component: _fb72cc32,
    name: "heroku-login___hu"
  }, {
    path: "/inspiracio",
    component: _47206ed6,
    name: "inspirationLandingPage___hu"
  }, {
    path: "/kedvencek",
    component: _00ad33c0,
    name: "favorites___hu"
  }, {
    path: "/kereses",
    component: _511ad2d2,
    name: "search___hu"
  }, {
    path: "/koszonjuk",
    component: _0b624f82,
    name: "koszonjuk___hu"
  }, {
    path: "/leiratkozas",
    component: _733c9bff,
    name: "leiratkozas___hu"
  }, {
    path: "/munkaertesito",
    component: _dd5630b6,
    name: "jobNotificationPage___hu"
  }, {
    path: "/nincs-jelszo",
    component: _61aa34d3,
    name: "noPassword___hu"
  }, {
    path: "/obi",
    component: _184d40c2,
    name: "obi___hu"
  }, {
    path: "/regisztracio",
    component: _b95be240,
    name: "registrationPage___hu",
    children: [{
      path: "professions",
      component: _735f8a6a,
      name: "registrationStepProfession___hu"
    }, {
      path: "/regisztracio",
      component: _524fed63,
      name: "registration___hu"
    }, {
      path: "/regisztracio/bemutatkozas",
      component: _a1700f48,
      name: "registrationStepIntroduction___hu"
    }, {
      path: "/regisztracio/email",
      component: _500d6820,
      name: "registrationStepEmail___hu"
    }, {
      path: "/regisztracio/jelszo",
      component: _ad4c4dc6,
      name: "registrationStepPassword___hu"
    }, {
      path: "/regisztracio/jobfeed",
      component: _b5fd23a2,
      name: "registrationJobfeed___hu"
    }, {
      path: "/regisztracio/nev",
      component: _35ab188d,
      name: "registrationStepName___hu"
    }, {
      path: "/regisztracio/profilkep",
      component: _56af3edc,
      name: "registrationStepPicture___hu"
    }, {
      path: "/regisztracio/siker",
      component: _c475fd52,
      name: "registrationStepSuccess___hu"
    }, {
      path: "/regisztracio/szamlakepesseg",
      component: _af78cffe,
      name: "registrationStepInvoice___hu"
    }, {
      path: "/regisztracio/telefonszam",
      component: _6a739f42,
      name: "registrationStepPhone___hu"
    }, {
      path: "/regisztracio/varos",
      component: _6615c126,
      name: "registrationStepCity___hu"
    }, {
      path: "/regisztracio/:_",
      component: _175a9af0,
      name: "registration-all___hu"
    }]
  }, {
    path: "/szakifizetesek",
    component: _31ae2552,
    name: "salariesPage___hu"
  }, {
    path: "/szakifizu",
    component: _336443bc,
    name: "szakifizu___hu"
  }, {
    path: "/szakivalaszol",
    component: _565205b9,
    name: "questionsPage___hu"
  }, {
    path: "/szakma",
    component: _317cd4b4,
    name: "professionsLandingPage___hu"
  }, {
    path: "/szerzodesmintak",
    component: _3dcf3a3e,
    name: "szerzodesmintak___hu"
  }, {
    path: "/tevekenysegek",
    component: _1709b8b2,
    name: "profession-tasks___hu"
  }, {
    path: "/uj-jelszo",
    component: _2c31a3dc,
    name: "newPasswordPage___hu"
  }, {
    path: "/uj-szakik",
    component: _06fc26ab,
    name: "new-professionals___hu"
  }, {
    path: "/velemeny",
    component: _6e9a3916,
    name: "velemeny___hu"
  }, {
    path: "/vezerlo",
    component: _4406f213,
    name: "conductorIndexPage___hu"
  }, {
    path: "/visszajelzes",
    component: _b6d5b74a,
    name: "visszajelzes___hu"
  }, {
    path: "/components/buttons",
    component: _7aa3a8ef,
    name: "components-buttons___hu"
  }, {
    path: "/components/inputs",
    component: _20d5d42a,
    name: "components-inputs___hu"
  }, {
    path: "/components/misc",
    component: _4b5bd1ee,
    name: "components-misc___hu"
  }, {
    path: "/cs/bez-hesla",
    component: _61aa34d3,
    name: "noPassword___cs"
  }, {
    path: "/cs/blog",
    component: _48caa40c,
    name: "blogLandingPage___cs"
  }, {
    path: "/cs/ceny",
    component: _71fcfa18,
    name: "pricesPage___cs"
  }, {
    path: "/cs/cinnosty",
    component: _1709b8b2,
    name: "profession-tasks___cs"
  }, {
    path: "/cs/conductor",
    component: _4406f213,
    name: "conductorIndexPage___cs"
  }, {
    path: "/cs/elso-belepes",
    component: _6495c202,
    name: "elso-belepes___cs"
  }, {
    path: "/cs/health-check",
    component: _2a3e2504,
    name: "health-check___cs"
  }, {
    path: "/cs/heroku-login",
    component: _fb72cc32,
    name: "heroku-login___cs"
  }, {
    path: "/cs/inspirace",
    component: _47206ed6,
    name: "inspirationLandingPage___cs"
  }, {
    path: "/cs/koszonjuk",
    component: _0b624f82,
    name: "koszonjuk___cs"
  }, {
    path: "/cs/leiratkozas",
    component: _733c9bff,
    name: "leiratkozas___cs"
  }, {
    path: "/cs/nove-heslo",
    component: _2c31a3dc,
    name: "newPasswordPage___cs"
  }, {
    path: "/cs/novi-odbornici",
    component: _06fc26ab,
    name: "new-professionals___cs"
  }, {
    path: "/cs/obi",
    component: _184d40c2,
    name: "obi___cs"
  }, {
    path: "/cs/oblibene",
    component: _00ad33c0,
    name: "favorites___cs"
  }, {
    path: "/cs/odpovedi-odborniku",
    component: _565205b9,
    name: "questionsPage___cs"
  }, {
    path: "/cs/oznameni-o-praci",
    component: _dd5630b6,
    name: "jobNotificationPage___cs"
  }, {
    path: "/cs/platy",
    component: _31ae2552,
    name: "salariesPage___cs"
  }, {
    path: "/cs/potvrzeni-emailu",
    component: _2d173a0a,
    name: "emailConfirmPage___cs"
  }, {
    path: "/cs/prihlaseni",
    component: _908b9d56,
    name: "login___cs"
  }, {
    path: "/cs/profese",
    component: _317cd4b4,
    name: "professionsLandingPage___cs"
  }, {
    path: "/cs/registrace",
    component: _b95be240,
    name: "registrationPage___cs",
    children: [{
      path: "professions",
      component: _735f8a6a,
      name: "registrationStepProfession___cs"
    }, {
      path: "/cs/registrace",
      component: _524fed63,
      name: "registration___cs"
    }, {
      path: "/cs/registrace/email",
      component: _500d6820,
      name: "registrationStepEmail___cs"
    }, {
      path: "/cs/registrace/fakturovani",
      component: _af78cffe,
      name: "registrationStepInvoice___cs"
    }, {
      path: "/cs/registrace/heslo",
      component: _ad4c4dc6,
      name: "registrationStepPassword___cs"
    }, {
      path: "/cs/registrace/jmeno",
      component: _35ab188d,
      name: "registrationStepName___cs"
    }, {
      path: "/cs/registrace/jobfeed",
      component: _b5fd23a2,
      name: "registrationJobfeed___cs"
    }, {
      path: "/cs/registrace/mesto",
      component: _6615c126,
      name: "registrationStepCity___cs"
    }, {
      path: "/cs/registrace/predstaveni",
      component: _a1700f48,
      name: "registrationStepIntroduction___cs"
    }, {
      path: "/cs/registrace/profilovka",
      component: _56af3edc,
      name: "registrationStepPicture___cs"
    }, {
      path: "/cs/registrace/tel-cislo",
      component: _6a739f42,
      name: "registrationStepPhone___cs"
    }, {
      path: "/cs/registrace/uspech",
      component: _c475fd52,
      name: "registrationStepSuccess___cs"
    }, {
      path: "/cs/registrace/:_",
      component: _175a9af0,
      name: "registration-all___cs"
    }]
  }, {
    path: "/cs/registrace-klienta",
    component: _5751fb74,
    name: "customerRegistrationPage___cs"
  }, {
    path: "/cs/szakifizu",
    component: _336443bc,
    name: "szakifizu___cs"
  }, {
    path: "/cs/szerzodesmintak",
    component: _3dcf3a3e,
    name: "szerzodesmintak___cs"
  }, {
    path: "/cs/velemeny",
    component: _6e9a3916,
    name: "velemeny___cs"
  }, {
    path: "/cs/visszajelzes",
    component: _b6d5b74a,
    name: "visszajelzes___cs"
  }, {
    path: "/cs/vop",
    component: _0a6a041a,
    name: "terms___cs"
  }, {
    path: "/cs/vyhledavani",
    component: _511ad2d2,
    name: "search___cs"
  }, {
    path: "/cs/zadost-o-nabidku",
    component: _5a727380,
    name: "requestForTender___cs"
  }, {
    path: "/fiokom/ajanlatkereseim",
    component: _62aad9cd,
    name: "myTenders___hu"
  }, {
    path: "/fiokom/araim",
    component: _3c131fce,
    name: "addPricesPage___hu"
  }, {
    path: "/fiokom/elofizetes",
    component: _6e41ee2c,
    name: "subscriptionPage___hu"
  }, {
    path: "/fiokom/ertekeleseim",
    component: _64fb9938,
    name: "RatingsPage___hu"
  }, {
    path: "/fiokom/folyamatban-levo-munkaim",
    component: _8d87a6a6,
    name: "boughtTendersPage___hu"
  }, {
    path: "/fiokom/megszerezheto-munkaim",
    component: _d066ed6a,
    name: "buyTendersPage___hu"
  }, {
    path: "/fiokom/referenciaim",
    component: _81489a52,
    name: "references___hu"
  }, {
    path: "/fizetes/folyamatban",
    component: _77b7d248,
    name: "fizetes-folyamatban___hu"
  }, {
    path: "/fizetes/sikeres",
    component: _c4e37d84,
    name: "fizetes-sikeres___hu"
  }, {
    path: "/fizetes/sikertelen",
    component: _bd5b12b8,
    name: "fizetes-sikertelen___hu"
  }, {
    path: "/static/rolunk",
    component: _ede13eea,
    name: "aboutUs___hu"
  }, {
    path: "/szakifizu/fizuk",
    component: _7a147332,
    name: "szakifizu-fizuk___hu"
  }, {
    path: "/szakivalaszol/szakma",
    redirect: "/questions"
  }, {
    path: "/szakivalaszol/tevekenyseg",
    redirect: "/questions"
  }, {
    path: "/szakivalaszol/uj-kerdes",
    component: _4a2811df,
    name: "addQuestionPage___hu"
  }, {
    path: "/velemeny/koszonjuk",
    component: _d6bb450e,
    name: "velemeny-koszonjuk___hu"
  }, {
    path: "/vezerlo/felhasznalok",
    component: _6a86b802,
    name: "conductorPersonsIndexPage___hu"
  }, {
    path: "/vezerlo/hirlevel-feliratkozasok",
    component: _5388a418,
    name: "conductorNewsletterSubscriptionsIndexPage___hu"
  }, {
    path: "/vezerlo/inspiraciok",
    component: _b61144ca,
    name: "conductorInspirationsIndexPage___hu"
  }, {
    path: "/vezerlo/kerdesek",
    component: _411086de,
    name: "conductorQuestionsIndexPage___hu"
  }, {
    path: "/vezerlo/szakmacsoportok",
    component: _31a3dc09,
    name: "conductorProfessionGroupsIndexPage___hu"
  }, {
    path: "/vezerlo/szakmak",
    component: _3894910a,
    name: "conductorProfessionsIndexPage___hu"
  }, {
    path: "/visszajelzes/koszonjuk",
    component: _420dbb5f,
    name: "visszajelzes-koszonjuk___hu"
  }, {
    path: "/cs/components/buttons",
    component: _7aa3a8ef,
    name: "components-buttons___cs"
  }, {
    path: "/cs/components/inputs",
    component: _20d5d42a,
    name: "components-inputs___cs"
  }, {
    path: "/cs/components/misc",
    component: _4b5bd1ee,
    name: "components-misc___cs"
  }, {
    path: "/cs/conductor/inspirace",
    component: _b61144ca,
    name: "conductorInspirationsIndexPage___cs"
  }, {
    path: "/cs/conductor/newsletter-prihlasky",
    component: _5388a418,
    name: "conductorNewsletterSubscriptionsIndexPage___cs"
  }, {
    path: "/cs/conductor/profese",
    component: _3894910a,
    name: "conductorProfessionsIndexPage___cs"
  }, {
    path: "/cs/conductor/skupiny-profesi",
    component: _31a3dc09,
    name: "conductorProfessionGroupsIndexPage___cs"
  }, {
    path: "/cs/fizetes/folyamatban",
    component: _77b7d248,
    name: "fizetes-folyamatban___cs"
  }, {
    path: "/cs/fizetes/sikeres",
    component: _c4e37d84,
    name: "fizetes-sikeres___cs"
  }, {
    path: "/cs/fizetes/sikertelen",
    component: _bd5b12b8,
    name: "fizetes-sikertelen___cs"
  }, {
    path: "/cs/muj-ucet/ceny",
    component: _3c131fce,
    name: "addPricesPage___cs"
  }, {
    path: "/cs/muj-ucet/dostupne-prace",
    component: _d066ed6a,
    name: "buyTendersPage___cs"
  }, {
    path: "/cs/muj-ucet/moje-hodnoceni",
    component: _64fb9938,
    name: "RatingsPage___cs"
  }, {
    path: "/cs/muj-ucet/moje-poptavky",
    component: _62aad9cd,
    name: "myTenders___cs"
  }, {
    path: "/cs/muj-ucet/predplatne",
    component: _6e41ee2c,
    name: "subscriptionPage___cs"
  }, {
    path: "/cs/muj-ucet/probihajici-prace",
    component: _8d87a6a6,
    name: "boughtTendersPage___cs"
  }, {
    path: "/cs/muj-ucet/reference",
    component: _81489a52,
    name: "references___cs"
  }, {
    path: "/cs/odpovedi-odborniku/nova-otazka",
    component: _4a2811df,
    name: "addQuestionPage___cs"
  }, {
    path: "/cs/static/o-nas",
    component: _ede13eea,
    name: "aboutUs___cs"
  }, {
    path: "/cs/szakifizu/fizuk",
    component: _7a147332,
    name: "szakifizu-fizuk___cs"
  }, {
    path: "/cs/velemeny/koszonjuk",
    component: _d6bb450e,
    name: "velemeny-koszonjuk___cs"
  }, {
    path: "/cs/vezerlo/felhasznalok",
    component: _6a86b802,
    name: "conductorPersonsIndexPage___cs"
  }, {
    path: "/cs/vezerlo/kerdesek",
    component: _411086de,
    name: "conductorQuestionsIndexPage___cs"
  }, {
    path: "/cs/visszajelzes/koszonjuk",
    component: _420dbb5f,
    name: "visszajelzes-koszonjuk___cs"
  }, {
    path: "/tema/sos/festes",
    component: _588fe78e,
    name: "tema-sos-festes___hu"
  }, {
    path: "/tema/sos/koszonjuk",
    component: _661c0f0a,
    name: "tema-sos-koszonjuk___hu"
  }, {
    path: "/vezerlo/ajanlatkeresek/biralas",
    component: _45db412a,
    name: "conductorTendersJudgeIndexPage___hu"
  }, {
    path: "/vezerlo/blog/cikkek",
    component: _7085908e,
    name: "conductorBlogArticlesIndexPage___hu"
  }, {
    path: "/vezerlo/blog/cimkek",
    component: _8ef50b2c,
    name: "conductorBlogTagsIndexPage___hu"
  }, {
    path: "/vezerlo/blog/gyujtemenyek",
    component: _18885b3a,
    name: "conductorBlogCollectionsIndexPage___hu"
  }, {
    path: "/vezerlo/inspiraciok/letrehozas",
    component: _4e4d78c6,
    name: "conductorInspirationsCreatePage___hu"
  }, {
    path: "/vezerlo/szakmacsoportok/letrehozas",
    component: _420efad0,
    name: "conductorProfessionGroupsCreatePage___hu"
  }, {
    path: "/vezerlo/szakmak/letrehozas",
    component: _691f7a34,
    name: "conductorProfessionsCreatePage___hu"
  }, {
    path: "/cs/conductor/blog/clanky",
    component: _7085908e,
    name: "conductorBlogArticlesIndexPage___cs"
  }, {
    path: "/cs/conductor/blog/kolekce",
    component: _18885b3a,
    name: "conductorBlogCollectionsIndexPage___cs"
  }, {
    path: "/cs/conductor/blog/znacky",
    component: _8ef50b2c,
    name: "conductorBlogTagsIndexPage___cs"
  }, {
    path: "/cs/conductor/blog/znacky-vytvorit",
    component: _5efb2517,
    name: "conductorBlogTagsCreatePage___cs"
  }, {
    path: "/cs/conductor/inspirace/vytvorit",
    component: _4e4d78c6,
    name: "conductorInspirationsCreatePage___cs"
  }, {
    path: "/cs/conductor/profese/vytvorit",
    component: _691f7a34,
    name: "conductorProfessionsCreatePage___cs"
  }, {
    path: "/cs/conductor/skupiny-profesi/vytvorit",
    component: _420efad0,
    name: "conductorProfessionGroupsCreatePage___cs"
  }, {
    path: "/cs/conductor/zadosti-o-nabidku/posouzeni",
    component: _45db412a,
    name: "conductorTendersJudgeIndexPage___cs"
  }, {
    path: "/cs/tema/sos/festes",
    component: _588fe78e,
    name: "tema-sos-festes___cs"
  }, {
    path: "/cs/tema/sos/koszonjuk",
    component: _661c0f0a,
    name: "tema-sos-koszonjuk___cs"
  }, {
    path: "/vezerlo/blog/cikkek/letrehozas",
    component: _c6fec31a,
    name: "conductorBlogArticlesCreatePage___hu"
  }, {
    path: "/vezerlo/blog/cimkek/letrehozas",
    component: _5efb2517,
    name: "conductorBlogTagsCreatePage___hu"
  }, {
    path: "/vezerlo/blog/gyujtemenyek/letrehozas",
    component: _721e8b72,
    name: "conductorBlogCollectionsCreatePage___hu"
  }, {
    path: "/cs/conductor/blog/clanky/vytvorit",
    component: _c6fec31a,
    name: "conductorBlogArticlesCreatePage___cs"
  }, {
    path: "/cs/conductor/blog/kolekce/vytvorit",
    component: _721e8b72,
    name: "conductorBlogCollectionsCreatePage___cs"
  }, {
    path: "/cs/conductor/blog/clanky/:id",
    component: _11e7b479,
    name: "conductorBlogArticlesEditPage___cs"
  }, {
    path: "/cs/conductor/blog/kolekce/:id",
    component: _10ed4925,
    name: "conductorBlogCollectionsEditPage___cs"
  }, {
    path: "/cs/conductor/blog/znacky/:id",
    component: _21e75055,
    name: "conductorBlogTagsEditPage___cs"
  }, {
    path: "/cs/blog/cinnost/:professionTaskSeoName",
    component: _4ca8bc10,
    name: "blogListPageByProfessionTask___cs"
  }, {
    path: "/cs/blog/kolekce/:collectionSeoName",
    component: _33530cd6,
    name: "blogListPageByCollection___cs"
  }, {
    path: "/cs/blog/profese/:professionSeoName",
    component: _25c6579a,
    name: "blogListPageByProfession___cs"
  }, {
    path: "/cs/blog/znacka/:tagSeoName",
    component: _a50d881c,
    name: "blogListPageByTag___cs"
  }, {
    path: "/cs/conductor/inspirace/:id",
    component: _213b99f4,
    name: "conductorInspirationsEditPage___cs"
  }, {
    path: "/cs/conductor/profese/:id",
    component: _dcbec634,
    name: "conductorProfessionsEditPage___cs"
  }, {
    path: "/cs/conductor/skupiny-profesi/:id",
    component: _3badca74,
    name: "conductorProfessionGroupsEditPage___cs"
  }, {
    path: "/cs/inspirace/cinnost/:professionTaskSeoName",
    component: _67413166,
    name: "inspirationProfessionTaskPage___cs"
  }, {
    path: "/cs/inspirace/profese/:professionSeoName",
    component: _1ffae464,
    name: "inspirationProfessionPage___cs"
  }, {
    path: "/cs/muj-ucet/moje-prace/:personTenderId",
    component: _533c1a7c,
    name: "myJobPage___cs"
  }, {
    path: "/cs/muj-ucet/nastaveni/:highlightedSetting?",
    component: _0b387cfc,
    name: "settingsPage___cs"
  }, {
    path: "/cs/szakifizu/atlag/:szakmaSeo",
    component: _5505892c,
    name: "szakifizu-atlag-szakmaSeo___cs"
  }, {
    path: "/cs/szakivalaszol/szakma/:profession",
    component: _3979a7c4,
    name: "professionQuestions___cs"
  }, {
    path: "/cs/szakivalaszol/tevekenyseg/:professionTask",
    component: _3979a7c4,
    name: "professionTaskQuestions___cs"
  }, {
    path: "/cs/vezerlo/felhasznalok/:id",
    component: _4f6001ed,
    name: "conductorPersonsEditPage___cs"
  }, {
    path: "/cs/vezerlo/kerdesek/:id",
    component: _240e26fc,
    name: "conductorQuestionEditPage___cs"
  }, {
    path: "/vezerlo/blog/cikkek/:id",
    component: _11e7b479,
    name: "conductorBlogArticlesEditPage___hu"
  }, {
    path: "/vezerlo/blog/cimkek/:id",
    component: _21e75055,
    name: "conductorBlogTagsEditPage___hu"
  }, {
    path: "/vezerlo/blog/gyujtemenyek/:id",
    component: _10ed4925,
    name: "conductorBlogCollectionsEditPage___hu"
  }, {
    path: "/cs/szakifizu/atlag/:szakmaSeo?/munkak",
    component: _7420f8b0,
    name: "szakifizu-atlag-szakmaSeo-munkak___cs"
  }, {
    path: "/blog/cimke/:tagSeoName",
    component: _a50d881c,
    name: "blogListPageByTag___hu"
  }, {
    path: "/blog/gyujtemeny/:collectionSeoName",
    component: _33530cd6,
    name: "blogListPageByCollection___hu"
  }, {
    path: "/blog/szakma/:professionSeoName",
    component: _25c6579a,
    name: "blogListPageByProfession___hu"
  }, {
    path: "/blog/tevekenyseg/:professionTaskSeoName",
    component: _4ca8bc10,
    name: "blogListPageByProfessionTask___hu"
  }, {
    path: "/cs/arcsekkolo/:professionSeoName",
    component: _1a9412cc,
    name: "arcsekkolo-professionSeoName___cs"
  }, {
    path: "/cs/blog/:articleSeoName",
    component: _759b63ea,
    name: "blogArticlePage___cs"
  }, {
    path: "/cs/ceny/:professionTask",
    component: _8b1fad3c,
    name: "prices___cs"
  }, {
    path: "/cs/inspirace/:inspirationSeoName",
    component: _62fd8224,
    name: "inspirationPage___cs"
  }, {
    path: "/cs/kraje/:county",
    component: _00df2ea8,
    name: "county___cs"
  }, {
    path: "/cs/odbornici/:profession",
    component: _8f771e38,
    name: "professionPage___cs"
  }, {
    path: "/cs/odbornik/:professionalSeoName",
    component: _40a8adb6,
    name: "professionalPage___cs"
  }, {
    path: "/cs/odpovedi-odborniku/:questionSeoName",
    component: _1536bb6f,
    name: "questionPage___cs"
  }, {
    path: "/cs/partner-ajanlatkeres/:partnerCode?",
    component: _5981deac,
    name: "partner-ajanlatkeres-partnerCode___cs"
  }, {
    path: "/cs/profese/:professionGroup",
    component: _ee6be1fa,
    name: "professionsPage___cs"
  }, {
    path: "/cs/projects/:id?",
    component: _58c66acc,
    name: "projects-id___cs"
  }, {
    path: "/cs/tender/:tenderId",
    component: _c819e8c6,
    name: "tender-tenderId___cs"
  }, {
    path: "/cs/valassz/:decisionTestSeoName?",
    component: _a1b16392,
    name: "valassz-decisionTestSeoName___cs"
  }, {
    path: "/cs/zadost-o-nabidku/:activeTab",
    component: _3e34b71c,
    name: "requestForTenderTab___cs"
  }, {
    path: "/fiokom/beallitasaim/:highlightedSetting?",
    component: _0b387cfc,
    name: "settingsPage___hu"
  }, {
    path: "/fiokom/munkam/:personTenderId",
    component: _533c1a7c,
    name: "myJobPage___hu"
  }, {
    path: "/inspiracio/szakma/:professionSeoName",
    component: _1ffae464,
    name: "inspirationProfessionPage___hu"
  }, {
    path: "/inspiracio/tevekenyseg/:professionTaskSeoName",
    component: _67413166,
    name: "inspirationProfessionTaskPage___hu"
  }, {
    path: "/szakifizu/atlag/:szakmaSeo",
    component: _5505892c,
    name: "szakifizu-atlag-szakmaSeo___hu"
  }, {
    path: "/szakivalaszol/szakma/:profession",
    component: _3979a7c4,
    name: "professionQuestions___hu"
  }, {
    path: "/szakivalaszol/tevekenyseg/:professionTask",
    component: _3979a7c4,
    name: "professionTaskQuestions___hu"
  }, {
    path: "/vezerlo/felhasznalok/:id",
    component: _4f6001ed,
    name: "conductorPersonsEditPage___hu"
  }, {
    path: "/vezerlo/inspiraciok/:id",
    component: _213b99f4,
    name: "conductorInspirationsEditPage___hu"
  }, {
    path: "/vezerlo/kerdesek/:id",
    component: _240e26fc,
    name: "conductorQuestionEditPage___hu"
  }, {
    path: "/vezerlo/szakmacsoportok/:id",
    component: _3badca74,
    name: "conductorProfessionGroupsEditPage___hu"
  }, {
    path: "/vezerlo/szakmak/:id",
    component: _dcbec634,
    name: "conductorProfessionsEditPage___hu"
  }, {
    path: "/cs/odbornik/:professionalSeoName/hodnoceni",
    component: _d01b40a8,
    name: "professionalRatingPage___cs"
  }, {
    path: "/cs/prace/:tenderId/specifikace",
    component: _502ba96f,
    name: "tender-tenderId-specify___cs"
  }, {
    path: "/szakifizu/atlag/:szakmaSeo?/munkak",
    component: _7420f8b0,
    name: "szakifizu-atlag-szakmaSeo-munkak___hu"
  }, {
    path: "/cs/odbornik/:professionalSeoName/admin/balance",
    component: _23a89800,
    name: "adminBalance___cs"
  }, {
    path: "/cs/odbornik/:professionalSeoName/admin/merge",
    component: _08378e5c,
    name: "adminMerge___cs"
  }, {
    path: "/cs/odbornik/:professionalSeoName/admin/person-event-log",
    component: _006ec025,
    name: "personEventLog___cs"
  }, {
    path: "/cs/odbornik/:professionalSeoName/admin/transfers",
    component: _269d9be8,
    name: "adminTransfers___cs"
  }, {
    path: "/cs/odbornici/:profession/odpovedi-odborniku/:questionSeoName",
    component: _15c88c18,
    name: "professionals-profession-szakivalaszol-questionSeoName___cs"
  }, {
    path: "/cs/prace/:tenderId/specifikace/:activeTab",
    component: _c108683a,
    name: "specifyTenderPage___cs"
  }, {
    path: "/cs/arcsekkolo/:professionSeoName?/:priceTagSeoName",
    component: _3ebeabe0,
    name: "arcsekkolo-professionSeoName-priceTagSeoName___cs"
  }, {
    path: "/cs/inspirace/:inspirationSeoName/:pictureSeoName",
    component: _4b7e0f5e,
    name: "inspirationPicturePage___cs"
  }, {
    path: "/cs/kalkulacka-cen/:professionTask/:step?",
    component: _e9c1468c,
    name: "priceCalculatorPage___cs"
  }, {
    path: "/cs/odbornici/:profession/:city",
    component: _cdddc348,
    name: "professionals___cs"
  }, {
    path: "/cs/kraje/:county/:city/:subCity?",
    component: _a8282a72,
    name: "countyCity___cs"
  }, {
    path: "/cs/odbornici/:profession/:parentCity/:city",
    component: _2d222478,
    name: "professionalsParentCity___cs"
  }, {
    path: "/ajanlatkeres/:activeTab",
    component: _3e34b71c,
    name: "requestForTenderTab___hu"
  }, {
    path: "/arak/:professionTask",
    component: _8b1fad3c,
    name: "prices___hu"
  }, {
    path: "/arcsekkolo/:professionSeoName",
    component: _1a9412cc,
    name: "arcsekkolo-professionSeoName___hu"
  }, {
    path: "/blog/:articleSeoName",
    component: _759b63ea,
    name: "blogArticlePage___hu"
  }, {
    path: "/inspiracio/:inspirationSeoName",
    component: _62fd8224,
    name: "inspirationPage___hu"
  }, {
    path: "/megyek/:county",
    component: _00df2ea8,
    name: "county___hu"
  }, {
    path: "/partner-ajanlatkeres/:partnerCode?",
    component: _5981deac,
    name: "partner-ajanlatkeres-partnerCode___hu"
  }, {
    path: "/projects/:id?",
    component: _58c66acc,
    name: "projects-id___hu"
  }, {
    path: "/szakember/:professionalSeoName",
    component: _40a8adb6,
    name: "professionalPage___hu"
  }, {
    path: "/szakemberek/:profession",
    component: _8f771e38,
    name: "professionPage___hu"
  }, {
    path: "/szakivalaszol/:questionSeoName",
    component: _1536bb6f,
    name: "questionPage___hu"
  }, {
    path: "/szakma/:professionGroup",
    component: _ee6be1fa,
    name: "professionsPage___hu"
  }, {
    path: "/tender/:tenderId",
    component: _c819e8c6,
    name: "tender-tenderId___hu"
  }, {
    path: "/valassz/:decisionTestSeoName?",
    component: _a1b16392,
    name: "valassz-decisionTestSeoName___hu"
  }, {
    path: "/munka/:tenderId/pontositsunk",
    component: _502ba96f,
    name: "tender-tenderId-specify___hu"
  }, {
    path: "/szakember/:professionalSeoName/ertekeles",
    component: _d01b40a8,
    name: "professionalRatingPage___hu"
  }, {
    path: "/szakember/:professionalSeoName/admin/balance",
    component: _23a89800,
    name: "adminBalance___hu"
  }, {
    path: "/szakember/:professionalSeoName/admin/merge",
    component: _08378e5c,
    name: "adminMerge___hu"
  }, {
    path: "/szakember/:professionalSeoName/admin/person-event-log",
    component: _006ec025,
    name: "personEventLog___hu"
  }, {
    path: "/szakember/:professionalSeoName/admin/transfers",
    component: _269d9be8,
    name: "adminTransfers___hu"
  }, {
    path: "/munka/:tenderId/pontositsunk/:activeTab",
    component: _c108683a,
    name: "specifyTenderPage___hu"
  }, {
    path: "/szakemberek/:profession/szakivalaszol/:questionSeoName",
    component: _15c88c18,
    name: "professionals-profession-szakivalaszol-questionSeoName___hu"
  }, {
    path: "/arcsekkolo/:professionSeoName?/:priceTagSeoName",
    component: _3ebeabe0,
    name: "arcsekkolo-professionSeoName-priceTagSeoName___hu"
  }, {
    path: "/arkalkulator/:professionTask/:step?",
    component: _e9c1468c,
    name: "priceCalculatorPage___hu"
  }, {
    path: "/inspiracio/:inspirationSeoName/:pictureSeoName",
    component: _4b7e0f5e,
    name: "inspirationPicturePage___hu"
  }, {
    path: "/szakemberek/:profession/:city",
    component: _cdddc348,
    name: "professionals___hu"
  }, {
    path: "/megyek/:county/:city/:subCity?",
    component: _a8282a72,
    name: "countyCity___hu"
  }, {
    path: "/szakemberek/:profession/:parentCity/:city",
    component: _2d222478,
    name: "professionalsParentCity___hu"
  }, {
    path: "/cs/*",
    component: _03cf66c8,
    name: "all___cs"
  }, {
    path: "/",
    component: _1d4b0d3b,
    name: "indexPage___hu"
  }, {
    path: "/*",
    component: _03cf66c8,
    name: "all___hu"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
