<template>
  <div>
    <slot name="label" :failed="failed">
      <p
        v-if="label"
        class="text-base font-bold"
        :class="{
          'text-error': failed,
        }"
      >
        {{ label }}
      </p>
    </slot>
    <div class="flex flex-wrap w-full relative">
      <JoszakiInputWrapper>
        <div
          class="text-gray-300 placeholder-gray-300 h-full cursor-pointer flex items-center gap-1 flex-initial"
          @click.stop="downClicked"
        >
          <template v-if="value.countryCode">
            <div class="flex-0">
              <IconComponent
                pack="flags"
                :icon="value.countryCode.iso.trim()"
                class="w-6"
              />
            </div>
            <span class="text-gray-700"> +{{ value.countryCode.code }} </span>
          </template>
          <template v-else>
            <span />
            <span />
          </template>
          <div v-if="!disableDropdown" class="flex-0">
            <IconComponent icon="angle-down" class="text-gray-700" />
          </div>
        </div>

        <input
          ref="input"
          v-model="state.internalValue"
          type="tel"
          class="flex-1 focus:outline-none min-w-0"
          :placeholder="placeholder"
          v-bind="$attrs"
          @keypress="numericOnly"
          @input="onInput"
          @keyup="$emit('keyup', $event)"
        />

        <div class="flex-0 flex items-center cursor-pointer text-gray-400">
          <IconComponent
            :class="{
              'text-success': !invalid,
              'text-error': invalid,
            }"
            :icon="invalid ? 'times-circle' : 'check-circle'"
          />
        </div>
      </JoszakiInputWrapper>

      <div
        v-show="state.showDropdown"
        class="absolute bg-white rounded-md z-10 border border-gray-300 shadow-md max-w-[6rem]"
        :class="{
          'top-full left-0 right-0': dropDownPosition === 'bottom',
          'bottom-full left-0 right-0': dropDownPosition === 'top',
        }"
      >
        <div ref="container" class="max-h-52 overflow-y-auto">
          <div
            v-for="countryCode in phoneCountryCodes"
            :key="countryCode.id"
            class="py-2 pl-2 font-semibold flex gap-2 items-center px-1 hover:bg-gray-100 cursor-pointer"
            @click="selectCountryCode(countryCode)"
          >
            <IconComponent
              pack="flags"
              :icon="countryCode.iso.trim()"
              class="w-6"
            />
            +{{ countryCode.code }}
          </div>
        </div>
      </div>
    </div>
    <p
      v-if="showErrorMsg"
      class="text-sm text-error"
      :class="{
        invisible: !failed,
      }"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { computed, reactive, useContext } from "@nuxtjs/composition-api";
import {
  formatNumber,
  isValidPhoneNumber,
  parseNumber,
} from "libphonenumber-js";
import { useVuelidate } from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import JoszakiInputWrapper from "./input/JoszakiInputWrapper.vue";

export default {
  components: { JoszakiInputWrapper },
  props: {
    value: {
      type: Object,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "search",
    },
    iconPack: {
      type: String,
      default: "fas",
    },
    validationState: {
      type: Object,
      required: false,
      default: () => {},
    },
    label: {
      type: String,
      default: null,
    },
    showErrorMsg: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disableDropdown: {
      type: Boolean,
      default: false,
    },
    dropDownPosition: {
      type: String,
      default: "bottom",
      validator: (value) => {
        return ["top", "bottom"].includes(value);
      },
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    try {
      const {
        $validation: { rules },
        i18n,
        store,
      } = useContext();

      const phoneCountryCodes = store.getters["data/phoneCountryCodes"];
      const state = reactive({
        internalValue: "",
        showDropdown: false,
      });

      function isPhoneValid(value) {
        if (!props.value.countryCode) {
          console.error("No country code selected");
          return false;
        }
        return (
          isValidPhoneNumber(
            value,
            props.value.countryCode.iso.trim().toUpperCase()
          ) &&
          new RegExp(props.value.countryCode.regexp).test(
            value.replaceAll(" ", "")
          )
        );
      }

      const phoneValid = computed(() => {
        return isPhoneValid(state.internalValue);
      });

      const validations = computed(() => {
        return {
          value: {
            number: {
              phone: helpers.withMessage(i18n.t("mobile.errorMsg"), (value) =>
                isPhoneValid(value)
              ),
            },
            countryCode: {
              required: rules.required,
            },
          },
        };
      });

      const formData = computed(() => ({
        value: props.value,
      }));

      const v$ = useVuelidate(validations.value, formData);

      return {
        v$,
        state,
        phoneValid,
        phoneCountryCodes,
      };
    } catch (error) {
      console.error("PhoneInput setup error", error);
    }
  },
  computed: {
    errorMessage() {
      return (
        this.validationState?.$errors?.[0]?.$message ||
        this.v$?.$errors?.[0]?.$message ||
        "placeholder so height is correctly computed"
      );
    },
    failed() {
      return this.validationState?.$error || this.v$.$error;
    },
    invalid() {
      return this.validationState?.$invalid || this.v$.$invalid;
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.state.internalValue = this.convertToInternational(newValue.number);
      },
      immediate: true,
    },
  },
  created() {
    const code = this.phoneCountryCodes.find(
      (countryCode) => countryCode.iso.trim() === process.env.COUNTRY
    );
    if (code) {
      this.selectCountryCode(code);
    }
  },
  mounted() {
    this.state.internalValue = this.convertToInternational(this.value.number);
    document.addEventListener("click", this.outsideClickHandler);
    if (this.autofocus) {
      this.focus();
    }
  },
  destroyed() {
    document.removeEventListener("click", this.outsideClickHandler);
  },
  methods: {
    onInput() {
      this.$emit("input", {
        ...this.value,
        number: this.state.internalValue.replaceAll(" ", ""),
      });
    },
    numericOnly(e) {
      this.$emit("keypress", e);
      if (e.key === "Backspace" || e.key === "Delete") {
        return true;
      }

      const value = e.target.value.replaceAll(" ", "");
      if (
        value.length === this.value.countryCode.maxLength &&
        // if text is selected, allow to overwrite it, otherwise prevent typing
        !(e.target.selectionEnd - e.target.selectionStart)
      ) {
        e.preventDefault();
        return;
      }

      if (e.key === " ") {
        return true;
      }

      if (isNaN(e.key)) {
        e.preventDefault();
        return false;
      }
    },
    convertToInternational(value) {
      if (!value) {
        return "";
      }
      try {
        const parsedNumber = parseNumber(
          value,
          this.value.countryCode?.iso.trim().toUpperCase()
        );
        const formatted = formatNumber(parsedNumber, "INTERNATIONAL");
        if (formatted.length < 3) {
          return value;
        }
        const cutOff = `${this.value.countryCode?.code}`.length + 1;
        return formatted.slice(cutOff).trim();
      } catch (error) {
        console.error("convertToInternational error", error);
        return value;
      }
    },
    selectCountryCode(countryCode) {
      this.state.showDropdown = false;
      this.$emit("input", {
        ...this.value,
        countryCode,
      });
    },
    downClicked() {
      if (this.disableDropdown) {
        return;
      }
      this.state.showDropdown = !this.state.showDropdown;
    },
    outsideClickHandler(e) {
      if (!this.$el.contains(e.target) && this.state.showDropdown) {
        this.state.showDropdown = false;
      }
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>
