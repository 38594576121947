import { useContext, ref, computed } from "@nuxtjs/composition-api";
import { useTextSearch } from "./useTextSearch";
import useCapital from "~/composables/useCapital";

export function useCitySearch(filterCapital = false) {
    const { store } = useContext();
    const { isCapital } = useCapital();
    const cityFilter = ref("");

    const allCities = store.getters["data/cities"];

    const cities = computed(() => {
        return filterCapital
          ? allCities.filter((c) => !isCapital(c))
          : allCities;
      });

    const { results: filteredCities } = useTextSearch(
      cityFilter,
      cities,
      {
        mapperFn: (city) => city.name.split("(")[0].trim(),
      }
    );

    return {
        cityFilter,
        cities,
        filteredCities,
    }
}