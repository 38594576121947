import { useContext } from "@nuxtjs/composition-api";

export function useSendPasswordRecovery() {
  const { $mutate } = useContext();

  return async function sendPasswordRecovery(payload) {
    const SEND_PASSWORD_RECOVERY = await import(
      "../mutations/sendPasswordRecoveryMutation.graphql"
    );
    return $mutate(
      SEND_PASSWORD_RECOVERY,
      {
        email: payload.email,
      },
      {
        toastEnabled: true,
        errorMessageKey: "login.passwordRecovery.error",
        successMessageKey: "login.passwordRecovery.success",
      }
    );
  };
}
