import {
  useContext,
  computed,
  reactive,
  useRoute,
} from "@nuxtjs/composition-api";
import { useVuelidate } from "@vuelidate/core";

import { helpers } from "@vuelidate/validators";
import RFT_SSR from "./rftSSR.graphql";
import CITY_BY_ID from "./cityById.graphql";
import PROFESSION_BY_ID from "./professionById.graphql";
import PROFESSION_QUESTIONS from "./professionQuestions.json";
import NEW_TENDER_MUTATION from "~/pages/request-for-tender/newTender.graphql";
import { useAuthStore } from "~/components/_refactored/auth";

import PROFESSION_TASKS from "~/graphql/professionTasks.graphql";

const MIN_DESCRIPTION_LENGTH = 10;
const MAX_DESCRIPTION_LENGTH = 500;
const TENDER_DATA_KEY = "jszk-tender";
const VERSION = "1.0.0";
export const JOSZAKI_TOKENKEY = "jszk_token-key";

const formData = reactive({
  name: "",
  profession: null,
  jobDescription: "",
  city: null,
  email: "",
  phone: {
    number: "",
    countryCode: null,
  },
  terms: false,
});

const state = reactive({
  mate: null,
  prefilled: false,
  modalOpen: false,
  loading: false,
  showFloater: true,
  step: 1,
  professionTasks: [],
});

function reset() {
  formData.name = "";
  formData.profession = null;
  formData.jobDescription = "";
  formData.city = null;
  formData.email = "";
  formData.phone = {
    number: "",
    countryCode: null,
  };
  formData.terms = false;
}

function open() {
  state.modalOpen = true;
}

function close() {
  state.modalOpen = false;
}

function hideFloater() {
  state.showFloater = false;
}

function showFloater() {
  state.showFloater = true;
}

function getTenderTokenKey() {
  return localStorage.getItem(JOSZAKI_TOKENKEY);
}

function modifyCityAndProfession() {
  state.prefilled = false;
}

export function useNewTender() {
  const {
    $validation: { rules },
    $helpers,
    $query,
    $cookie,
    $mutate,
    $track,
    $trackers,
    i18n,
    store,
  } = useContext();
  const route = useRoute();
  const authStore = useAuthStore();

  function saveToStorage() {
    if (process.client) {
      console.debug("saving to cookie", getPayload());
      localStorage.setItem(
        TENDER_DATA_KEY,
        JSON.stringify({
          ...getPayload(),
          __version: VERSION,
        })
      );
    } else {
      throw new Error("saveToStorage is only available on client side");
    }
  }

  async function loadFromStorage() {
    if (process.server) {
      throw new Error("loadFromStorage is only available on client side");
    }
    const data = localStorage.getItem(TENDER_DATA_KEY);
    console.debug("loading data from storage", data);
    if (data) {
      const parsed = JSON.parse(data);
      if (parsed.__version !== VERSION) {
        localStorage.removeItem(TENDER_DATA_KEY);
        return;
      }

      const cityQueryId = route.value.query.cityId;
      const professionQueryId = route.value.query.professionId;
      console.debug("loadFromStorage parsed", parsed);
      formData.name = parsed.name;
      formData.jobDescription = parsed.description;
      formData.email = parsed.email;
      formData.terms = parsed.terms;
      formData.phone.number = parsed.phone;

      const promises = [];
      if (
        parsed.cityId &&
        formData.city?.id !== parsed.cityId &&
        !cityQueryId
      ) {
        promises.push($query(CITY_BY_ID, { cityId: parsed.cityId }));
      } else {
        promises.push(Promise.resolve(null));
      }

      if (
        parsed.professionId &&
        formData.profession?.id !== parsed.professionId &&
        !professionQueryId
      ) {
        promises.push(
          $query(PROFESSION_BY_ID, { professionId: parsed.professionId })
        );
      } else {
        promises.push(Promise.resolve(null));
      }

      const [city, profession] = await Promise.all(promises);

      if (
        parsed.phoneCountryCodeId &&
        formData.phone.countryCode?.id !== parsed.phoneCountryCodeId
      ) {
        const countryCode = store.getters["data/phoneCountryCodes"].find(
          // Json automatically converts id to number
          // eslint-disable-next-line eqeqeq
          (cc) => cc.id == parsed.phoneCountryCodeId
        );
        formData.phone = {
          number: parsed.phone,
          countryCode,
        };
      } else {
        formData.phone.number = parsed.phone;
      }

      if (city?.city && !formData.city) {
        formData.city = city.city;
      }
      if (profession?.professionById && !formData.profession) {
        formData.profession = profession.professionById;
      }
    }
  }

  const profession = computed({
    get() {
      return formData.profession;
    },
    set(value) {
      formData.profession = value;
    },
  });

  const jobDescription = computed({
    get() {
      return formData.jobDescription;
    },
    set(value) {
      if (!value) {
        formData.jobDescription = "";
        return;
      }
      const newValue = value.substring(0, MAX_DESCRIPTION_LENGTH);
      formData.jobDescription =
        newValue?.length > 2 ? $helpers.capitalize(newValue) : newValue;
    },
  });

  const descriptionHint = computed(() => {
    if (formData.profession) {
      return PROFESSION_QUESTIONS[formData.profession.seoName];
    }
    return null;
  });

  const city = computed({
    get() {
      return formData.city;
    },
    set(value) {
      formData.city = value;
    },
  });

  const name = computed({
    get() {
      return formData.name;
    },
    set(value) {
      formData.name = value?.length > 2 ? $helpers.capitalize(value) : value;
    },
  });

  const phone = computed({
    get() {
      return formData.phone;
    },
    set(value) {
      formData.phone = value;
    },
  });

  const email = computed({
    get() {
      return formData.email;
    },
    set(value) {
      formData.email = value;
    },
  });

  const terms = computed({
    get() {
      return formData.terms;
    },
    set(value) {
      formData.terms = value;
    },
  });

  function prefill(data) {
    loadFromStorage();
    // { profession, city, mate, description }
    profession.value = data.profession;
    city.value = data.city;
    state.mate = data.mate;
    state.prefilled = !!city.value && !!profession.value;
    if (data.description) {
      jobDescription.value = data.description;
    }
  }

  async function prefillWithIds() {
    const personId = route.value.query.personId ?? "0";
    const cityId = route.value.query.cityId;
    const professionId = route.value.query.professionId;

    try {
      if (state.mate?.id === personId) {
        return;
      }
      const resp = await $query(RFT_SSR, {
        mateId: personId,
        cityId,
        professionId,
      });

      if (resp?.personPublicProfile) {
        state.mate = resp.personPublicProfile;
      }
      if (cityId) {
        formData.city = resp?.city;
      }
      if (professionId) {
        formData.profession = resp?.professionById;
      }

      const professtionTasksResponse = await $query(PROFESSION_TASKS);
      state.professionTasks = professtionTasksResponse.professionTasks;

      state.prefilled = true;
    } catch (e) {
      console.error(e);
    }
  }

  const formRules = {
    name: {
      required: rules.required,
      minLength: rules.minLength(3),
    },
    profession: {
      required: rules.required,
    },
    jobDescription: {
      required: rules.required,
      length: helpers.withMessage(
        ({ $model }) =>
          i18n.t("newTender.minDescription", {
            minLength: MIN_DESCRIPTION_LENGTH,
            remaining: MIN_DESCRIPTION_LENGTH - ($model?.length ?? 0),
          }),
        (value) => value.length >= MIN_DESCRIPTION_LENGTH
      ),
    },
    city: {
      required: rules.required,
    },
    email: {
      required: rules.required,
      email: rules.email,
    },
    terms: {
      required: rules.consentRequired,
    },
    phone: {
      number: {
        required: rules.required,
      },
      countryCode: {
        required: rules.required,
      },
    },
  };

  const personHasSelectedProfession = computed(() => {
    if (!state.mate || !formData.profession || !formData.city) {
      return true;
    }
    const hasProfession = state.mate.professions?.some(
      (p) => p.id === formData.profession.id
    );

    const hasPersonProfession = state.mate.personProfessions?.some(
      (p) => p.profession.id === formData.profession.id
    );

    const hasMiniMarketForCity = state.mate.miniMarkets?.some(
      (m) =>
        m.miniMarket.professionId === formData.profession.id &&
        m.miniMarket.subcounties.some(
          (subcounty) =>
            subcounty.id === formData.city.subcountyId ||
            (subcounty.countyId === "1" && formData.city.countyId === "1") // ha BP-t választotta, akkor engedjük a BP-s miniMarketeket
        )
    );

    return hasProfession || hasPersonProfession || hasMiniMarketForCity;
  });

  const v$ = useVuelidate(formRules, formData);

  function getPayload() {
    return {
      name: formData.name,
      email: formData.email,
      phone: formData.phone.number,
      phoneCountryCodeId: parseInt(formData.phone.countryCode?.id),
      description: formData.jobDescription,
      cityId: parseInt(formData.city?.id),
      professionId: parseInt(formData.profession?.id),
      mateId:
        state.mate && personHasSelectedProfession.value
          ? parseInt(state.mate.id)
          : null,
      campaignsCookie: $cookie.get("campaigns"),
    };
  }

  function trackSubmitJob() {
    $track.analyticsEvent({
      event: "submit_job",
      category: "ajanlatkeres",
      action: "Submit job",
      label: "v2.0",
    });

    $trackers.trackEvent({
      title: "Submit Job",
      data: {
        professionSeoName: formData.profession.seoName,
        professionGroupSeoName: formData.profession?.professionGroup?.seoName,
        citySeoName: formData.city.seoName,
        v: 2,
      },
    });
  }

  function trackJobSubmitted(tenderId) {
    $track.analyticsEvent({
      category: "ajanlatkeres",
      action: "normal",
    });

    $trackers.trackEvent({
      title: "Job Submitted",
      data: {
        professionSeoName: profession.value?.seoName,
        professionGroupSeoName: profession.value?.professionGroup?.seoName,
        citySeoName: city.value?.seoName,
        personId:
          state.mate && personHasSelectedProfession.value
            ? parseInt(state.mate.id)
            : null,
        personIsActive:
          state.mate && personHasSelectedProfession.value
            ? state.mate?.isActive
            : null,
      },
    });

    $track.analyticsEvent({
      event: "job_submitted",
      category: "ajanlatkeres",
      action: "Job Submitted",
      label: "v1.0",
    });

    if (profession.value) {
      $track.customEvent({
        event: "Facebook.track",
        content: {
          eventName: "Lead",
          eventData: {
            content_name: profession.value?.seoName,
            content_category: profession.value?.professionGroup?.seoName,
            content_ids: [profession.value?.id],
            content_type: "profession",
          },
          eventMeta: {
            eventID: `tender_${tenderId}`,
          },
        },
      });
    }
  }

  async function createTender(skipValidation = false, trackEvent = true) {
    try {
      state.loading = true;
      if (!skipValidation) {
        const valid = await this.v$.$validate();
        if (!valid) {
          return Promise.reject(new Error("Validation failed"));
        }
      }
      saveToStorage();

      if (trackEvent) {
        trackSubmitJob();
      }

      const { tender, tenderKey, token } = await $mutate(
        NEW_TENDER_MUTATION,
        getPayload()
      );

      if (trackEvent) {
        trackJobSubmitted(tender.id);
      }

      authStore.setToken(token);
      localStorage.setItem(JOSZAKI_TOKENKEY, tenderKey);
      return Promise.resolve({ tender, tenderKey, token });
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    } finally {
      state.loading = false;
    }
  }

  return {
    v$,
    jobDescription,
    profession,
    city,
    name,
    reset,
    state,
    email,
    terms,
    minDescriptionLength: MIN_DESCRIPTION_LENGTH,
    maxDescriptionLength: MAX_DESCRIPTION_LENGTH,
    prefillWithIds,
    personHasSelectedProfession,
    phone,
    formData,
    saveToStorage,
    loadFromStorage,
    createTender,
    getPayload,
    getTenderTokenKey,
    descriptionHint,
    prefill,
    open,
    close,
    modifyCityAndProfession,
    hideFloater,
    showFloater,
    isOpen: computed(() => state.modalOpen),
  };
}
