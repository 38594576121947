import { useContext, computed, unref } from "@nuxtjs/composition-api";

/**
 * If the words start from the same position, sort alphabetically.
 * Otherwise if a word occurs earlier in the keyword, it will be sorted first.
 **/
function sortMatches(a, b) {
  if (a.keywordMatchedFrom === b.keywordMatchedFrom) {
    return a.text > b.text ? 1 : -1;
  }
  return a.keywordMatchedFrom > b.keywordMatchedFrom ? 1 : -1;
}

function defaultMapper(value) {
  return value.name;
}

/**
 * It will compare the searched keyword with the specified key in the items array.
 * If the keyword is found in the key, it will return the items that contain the keyword.
 * @param {String} keyword - The keyword to search.
 * @param {Array} items - The array of object to search.
 * @param {Object} options - Optional paramteres to adjust search:
 *  * compareFn: used to sort the results (with Array.prototype.sort()). Default is sortMatches.
 *  * mapperFn: used to map the object to the string on which the search should be conducted. Default is (value) => value.name.
 * @returns
 */
export function useTextSearch(keyword, items, options = {}) {
  const { $helpers } = useContext();

  const results = computed(() => {
    const normalizedKeyword = $helpers.normalizeInput(keyword.value);

    const matches = [];
    const itemsUnref = unref(items);
    for (const item of itemsUnref) {
      const mapperFn = options?.mapperFn ?? defaultMapper;
      const value = mapperFn(item);

      const index = $helpers
        .normalizeInput(value)
        .indexOf(normalizedKeyword);
      if (index > -1) {
        matches.push({
          keywordMatchedFrom: index,
          item,
          text: value,
        });
      }
    }
    matches.sort(options?.compareFn ?? sortMatches);

    return matches.map((x) => x.item);
  });

  return { results };
}
