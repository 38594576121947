<template>
  <div
    class="dark:text-white fixed top-0 left-0 right-0 w-full lg:p-4 md:inset-0 h-full justify-center items-center bg-white-darker/90 overscroll-contain isolate z-[1000]"
    :class="{
      flex: open,
      hidden: !open,
    }"
    @click.self="close(true)"
  >
    <!--  -->
    <dialog
      class="w-full bg-white rounded-lg shadow-md flex flex-col isolate"
      arial-modal="true"
      :class="{
        'max-w-xl max-h-[60%]': size === 'sm',
        'max-w-2xl max-h-[75%]': size === 'md',
        'max-w-screen-lg max-h-[100%]': size === 'lg',
      }"
    >
      <div
        class="flex-none flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600"
      >
        <slot name="header">
          <h3 class="text-xl font-semibold text-gray-900">
            {{ title }}
          </h3>
        </slot>
        <div class="cursor-pointer" @click="close(false)">
          <IconComponent
            class="text-gray-600 dark:text-white m-1 cursor-pointer"
            icon="times"
          />
        </div>
      </div>
      <div class="flex-initial min-h-0 p-4 space-y-6 overflow-auto">
        <slot name="body"> Joszaki body </slot>
      </div>
      <template v-if="!hideFooter">
        <div
          class="flex-none flex items-center space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600 p-4"
        >
          <slot name="footer">
            <JoszakiButton type="error" class="flex-1" @click="$emit('cancel')">
              {{ $t("common.cancel") }}
            </JoszakiButton>
            <JoszakiButton
              type="success"
              class="flex-1"
              :loading="loading"
              @click="$emit('confirm')"
            >
              {{ confirmButtonLabel ?? $t("common.accept") }}
            </JoszakiButton>
          </slot>
        </div>
      </template>
    </dialog>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    size: {
      type: String,
      default: "md",
      validator: (val) => ["sm", "md", "lg", "full"].includes(val),
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    confirmButtonLabel: {
      type: String,
      default: null,
    },
  },
  watch: {
    open: {
      immediate: true,
      handler(val) {
        if (process.server) {
          return;
        }
        if (val) {
          document.addEventListener("keydown", this.escapeListener);
          // document.body.classList.add('overflow-hidden')
        } else {
          document.removeEventListener("keydown", this.escapeListener);
          // document.body.classList.remove('overflow-hidden')
        }
      },
    },
  },
  methods: {
    escapeListener(evt) {
      if (evt?.key === "Escape") {
        this.close(false);
      }
    },
    close(isOutsideClick = false) {
      this.$emit("close", isOutsideClick);
    },
  },
};
</script>
