<template>
  <div class="JoszakiAutocomplete">
    <slot name="label" :failed="failed">
      <p
        v-if="label"
        class="text-base font-bold"
        :class="{
          'text-error': failed,
        }"
      >
        {{ label }}
      </p>
    </slot>
    <div class="flex flex-wrap w-full gap-2">
      <div ref="dropdownToggle" class="w-full">
        <JoszakiInputWrapper
          :disabled="_disabled"
          class="hover:cursor-pointer"
          @click.native.stop="downClicked"
        >
          <IconComponent
            :pack="iconPack"
            :icon="icon"
            class="text-gray-300 placeholder-gray-300 w-6"
          />

          <div class="flex-1 flex flex-wrap gap-2">
            <template v-if="isMultiSelect">
              <slot name="tags">
                <span
                  v-for="(item, index) in selectedItems"
                  :key="index"
                  class="bg-primary text-white px-2 py-1 font-bold text-xs rounded-md cursor-default flex flex-row items-center gap-1"
                >
                  {{ formatter(item) }}
                  <IconComponent
                    icon="times-circle"
                    class="inline cursor-pointer"
                    @click.native="() => removeItem(item)"
                  />
                </span>
              </slot>
            </template>

            <input
              ref="input"
              :value="value"
              class="focus:outline-none flex-shrink-0 flex-grow"
              :class="{ '!bg-gray-100': _disabled }"
              :placeholder="placeholder"
              :disabled="_disabled"
              @input="onInput"
              @click.stop="inputClicked"
              @touchend="inputClicked"
              @keydown.stop="onKeyDown"
              @focus="inputFocused"
            />
          </div>
          <div
            v-if="shouldShowDownIcon"
            class="h-full flex items-center cursor-pointer text-gray-400"
          >
            <IconComponent icon="angle-down" @click.native.stop="downClicked" />
          </div>
          <div
            v-if="shouldShowClearIcon"
            class="h-full flex items-center cursor-pointer text-gray-400"
          >
            <IconComponent icon="times" @click.native.stop="clear" />
          </div>
        </JoszakiInputWrapper>
      </div>

      <div
        v-show="showDropdown"
        ref="dropdownMenu"
        class="bg-white rounded-md z-10 border border-gray-300 shadow-md"
      >
        <div
          ref="container"
          class="max-h-52 overflow-y-auto"
          :class="{
            'py-2': !groupField,
          }"
        >
          <JoszakiLoading :active="loading" />
          <template v-if="groupedItemsArray.length">
            <div
              v-for="fieldItems of groupedItemsArray"
              :key="fieldItems[0].id"
            >
              <div v-if="groupField" class="font-bold p-2 text-black">
                {{ fieldItems[0][groupField] }}
              </div>
              <div
                v-for="item of fieldItems"
                :key="item[idField]"
                :ref="item[idField]"
                class="max-md:py-2 md:py-1 pl-3 pr-2 cursor-pointer hover:bg-gray-100 text-sm text-black"
                :class="{
                  'bg-gray-100': indexId === item[idField],
                }"
                @click.stop.prevent="selectItem(item)"
              >
                {{ formatter(item) }}
              </div>
            </div>
          </template>
          <template v-else>
            <div class="py-1 pl-3 pr-2 cursor-pointer text-sm">
              <slot name="empty" />
            </div>
          </template>
        </div>
      </div>
      <!--  -->
    </div>
    <p
      v-if="showErrorMsg"
      class="text-sm text-error"
      :class="{
        invisible: !failed,
      }"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import { inject } from "@nuxtjs/composition-api";
import JoszakiInputWrapper from "./input/JoszakiInputWrapper.vue";

const KEYWORD = "empty";

// Popper.js modifier to set the width of the dropdown to the width of the input
// Source: https://popper.js.org/docs/v2/modifiers/community-modifiers/
const sameWidth = {
  name: "sameWidth",
  enabled: true,
  phase: "beforeWrite",
  requires: ["computeStyles"],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
  },
};

export default {
  components: { JoszakiInputWrapper },
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      default: "search",
    },
    idField: {
      type: String,
      default: "id",
    },
    iconPack: {
      type: String,
      default: "fas",
    },
    groupField: {
      type: String,
      default: null,
    },
    formatter: {
      type: Function,
      default: (item) => item,
    },
    keepFirst: {
      type: Boolean,
      default: true,
    },
    validationState: {
      type: Object,
      required: false,
      default: () => {},
    },
    label: {
      type: String,
      default: null,
    },
    showErrorMsg: {
      type: Boolean,
      default: false,
    },
    reserveErrorSpace: {
      type: Boolean,
      default: true,
    },
    // TODO: currently Autocomplete is rather implemented as a select. This should be refactored. When this mode is on, the component's behaviour will resemble more the logic of an autocomplete field. In this mode the dropdown is shown only when the input is not empty.
    trueAutocompleteMode: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isMultiSelect: {
      type: Boolean,
      default: false,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxItems: {
      type: Number,
      default: 0,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { disabled: formDisabled } = inject("form", { disabled: false });
    return {
      formDisabled,
    };
  },
  data() {
    return {
      selectedIndex: 0,
      showDropdown: false,
    };
  },
  computed: {
    _disabled() {
      return this.disabled || this.formDisabled;
    },
    indexItem() {
      return this.flattenedArray[this.selectedIndex];
    },
    indexId() {
      return this.indexItem?.[this.idField];
    },
    flattenedArray() {
      const result = [];
      for (const entry of this.groupedItems.entries()) {
        result.push(...entry[1]);
      }
      return result;
    },
    groupedItems() {
      const items = new Map();
      for (const item of this.items) {
        const key = this.groupField ? item[this.groupField] : KEYWORD;
        if (!items.has(key)) {
          items.set(key, []);
        }
        items.get(key).push(item);
      }
      const sorted = [...items].sort((a, b) => a[0].localeCompare(b[0]));

      return new Map(sorted);
    },
    groupedItemsArray() {
      const result = [];
      for (const entry of this.groupedItems.entries()) {
        result.push(entry[1]);
      }
      return result;
    },
    errorMessage() {
      return (
        this.validationState?.$errors?.[0]?.$message ??
        (this.reserveErrorSpace
          ? "placeholder so height is correctly computed"
          : "")
      );
    },
    failed() {
      return this.validationState?.$error;
    },
    shouldShowClearIcon() {
      return !this.trueAutocompleteMode && this.clearable && this.value;
    },
    shouldShowDownIcon() {
      return !this.trueAutocompleteMode && (!this.clearable || !this.value);
    },
  },
  mounted() {
    document.addEventListener("click", this.outsideClickHandler);
    if (this.autofocus) {
      this.focusInput();
    }
  },
  destroyed() {
    document.removeEventListener("click", this.outsideClickHandler);
    if (this.popper) {
      this.popper.destroy();
    }
  },
  methods: {
    lazyInitPopper() {
      if (!this.popper) {
        this.popper = createPopper(
          this.$refs.dropdownToggle,
          this.$refs.dropdownMenu,
          {
            placement: "bottom-start",
            modifiers: [sameWidth],
          }
        );
      }
    },
    openDropdown() {
      this.showDropdown = true;
      this.setPopperEventListeners(true);
    },
    hideDropdown() {
      this.showDropdown = false;
      this.setPopperEventListeners(false);
    },
    setPopperEventListeners(enabled) {
      this.lazyInitPopper();
      this.popper.setOptions((options) => ({
        ...options,
        modifiers: [...options.modifiers, { name: "eventListeners", enabled }],
      }));
    },
    onInput(e) {
      this.$emit("input", e.target.value);
      this.selectedIndex = 0;
      if (this.trueAutocompleteMode && e && e.target.value.length === 0) {
        this.hideDropdown();
      } else {
        this.openDropdown();
      }
    },
    clear() {
      this.$emit("select", null);
      this.$emit("input", "");
    },
    inputFocused() {
      if (!this.trueAutocompleteMode) {
        this.openDropdown();
      }
      this.$emit("focus");
    },
    focusInput() {
      this.$refs.input.focus();
      // Timeout is needed to prevent the dropdown from closing immediately because of outside click handler
      this.$nextTick(() => {
        this.openDropdown();
      });
    },
    isContainer(el) {
      return el === this.$refs.container;
    },
    outsideClickHandler(e) {
      if (!this.$el.contains(e.target) && this.showDropdown) {
        this.hideDropdown();
        this.$emit("blur");
      }
    },
    inputClicked() {
      if (!this.trueAutocompleteMode) {
        this.showDropdown = true;
      }
    },
    onKeyDown(e) {
      if (e.key === "ArrowDown") {
        this.selectedIndex = Math.min(
          this.flattenedArray.length - 1,
          this.selectedIndex + 1
        );
        const el = this.$refs[this.indexId]?.[0];
        this.checkAndScroll(el);
      } else if (e.key === "ArrowUp") {
        this.selectedIndex = Math.max(0, this.selectedIndex - 1);
        const el = this.$refs[this.indexId]?.[0];
        this.checkAndScroll(el);
      } else if (e.key === "Enter") {
        this.selectItem(this.indexItem);
      } else if (e.key === "Escape") {
        if (this.keepFirst) {
          this.selectedIndex = 0;
          this.selectItem(this.indexItem);
        }
        this.$refs.input.blur();
      }
    },
    downClicked() {
      if (this.showDropdown) {
        this.hideDropdown();
      } else {
        this.$refs.input.focus();
      }
    },
    selectItem(item) {
      if (
        this.isMultiSelect &&
        this.maxItems &&
        this.selectedItems.length >= this.maxItems
      ) {
        return;
      }
      this.$emit("select", item);
      if (item && !this.isMultiSelect) {
        this.$emit("input", this.formatter(item));
        this.hideDropdown();
      }
      if (this.isMultiSelect) {
        this.$emit("input", "");
        this.$refs.input.focus();
      }
    },
    removeItem(item) {
      if (!this.isMultiSelect) {
        return;
      }
      this.$emit("remove", item);
    },
    checkAndScroll(el) {
      const rect = el.getBoundingClientRect();
      const top = rect.top;
      const bottom = rect.bottom;
      const container = this.$refs.container;
      const containerRect = container.getBoundingClientRect();
      const topWithinContainer = top >= containerRect.top;
      const bottomWithinContainer = bottom <= containerRect.bottom;
      if (!bottomWithinContainer) {
        container.scrollBy({
          top: rect.bottom - containerRect.bottom,
        });
      } else if (!topWithinContainer) {
        container.scrollBy({
          top: rect.top - containerRect.top,
        });
      }
    },
  },
};
</script>
