import { omit } from "lodash";
import { useLogout } from "~/components/_refactored/auth";
import { USER_JWT_TOKEN_COOKIE_NAME } from "~/components/_refactored/auth/constants";
import { GraphQLError, GraphQLUserError } from "~/components/_refactored/error";

export default function (context, inject) {
  const { app, redirect, localePath, $joszaki, $logger, $sentry } = context;

  function reportResponseErrorsIfAny(err) {
    if (!err.response?.errors) {
      return;
    }
    err.response.errors.forEach((error) => {
      const wrappedError = new GraphQLError(error);

      wrappedError.reportToSentry($sentry);
    });
  }

  const on401 = () => {
    try {
      const token = app.$cookie.get(USER_JWT_TOKEN_COOKIE_NAME);
      // Only report error if token is present and hasn't expired
      context.$sentry.addBreadcrumb({
        category: "auth",
        message: "document cookie: " + document?.cookie,
        level: "info",
      });

      context.$sentry.addBreadcrumb({
        category: "auth",
        message: "Token: " + token,
        level: "info",
      });
      context.$sentry.captureException(new Error("UNAUTHENTICATED"), {
        tags: {
          auth: "redirect",
        },
      });
    } catch (e) {
      $logger.error("UNAUTHENTICATED error", e);
    }
    const logout = useLogout();
    logout();

    const route = context.route;
    if (route.path.startsWith(app.localePath({ name: "registrationPage" }))) {
      redirect(401, localePath({ name: "login" }));
    } else {
      const uri = encodeURIComponent(route.fullPath);
      redirect(401, localePath({ name: "login" }), { "callback-url": uri });
    }
  };

  const on403 = () => {
    $joszaki.toast({
      message: app.i18n.t("error.FORBIDDEN"),
      type: "error",
    });
    const route = context.route;
    const uri = encodeURIComponent(route.fullPath);
    redirect(401, localePath({ name: "login" }), { "callback-url": uri });
  };

  function checkErrorCodes(err) {
    if (err.graphQLErrors) {
      for (const error of err.graphQLErrors) {
        if (app.i18n.te(`error.${error.message}`)) {
          return app.i18n.t(`error.${error.message}`);
        }
      }
    }
    if (err.userErrors) {
      for (const error of err.userErrors) {
        if (app.i18n.te(`error.${error.message}`)) {
          return app.i18n.t(`error.${error.message}`, error?.data ?? {});
        }
        if (error?.message === "NO_PASSWORD") {
          redirect(
            403,
            `${app.$helpers.topDomain}${app.localePath({ name: "noPassword" })}`
          );
        }
      }
    }
    if (err.response?.errors) {
      for (const error of err.response.errors) {
        if (app.i18n.te(`error.${error.message}`)) {
          return app.i18n.t(`error.${error.message}`);
        }
        if (error?.code === "UNAUTHENTICATED") {
          on401();
        }
        if (error.code === "FORBIDDEN" || error.message === "ADMIN_ONLY") {
          on403();
        }
      }
    }
    return undefined;
  }

  async function mutate(
    mutation,
    variables = {},
    {
      toastEnabled = false,
      errorMessageKey = "common.operationError",
      successMessageKey = "common.operationSuccess",
    } = {}
  ) {
    const mutationName = mutation.definitions?.[0]?.name?.value;
    const requestHeaders = {
      authorization: `Bearer ${app.$cookie.get(USER_JWT_TOKEN_COOKIE_NAME)}`,
    };
    try {
      $logger.info("mutation called", {
        mutationName,
        variables: omit(variables, ["password", "newPassword"]),
      });

      const result = await context.$graphql.default.request(
        mutation,
        variables,
        requestHeaders
      );
      if (result[mutationName]?.userErrors) {
        throw result[mutationName];
      }
      if (toastEnabled) {
        $joszaki.toast({
          message: app.i18n.t(successMessageKey),
          type: "success",
        });
      }
      return Promise.resolve(result[mutationName]);
    } catch (err) {
      reportResponseErrorsIfAny(err);

      if (err.userErrors) {
        err.userErrors.forEach((error) => {
          const wrappedError = new GraphQLUserError({ ...error, mutationName });

          wrappedError.reportToSentry(context.$sentry);
        });
      }

      let message = checkErrorCodes(err);
      if (message) {
        if (toastEnabled) {
          $joszaki.toast({
            message,
            type: "error",
          });
        }
        return Promise.reject(message);
      }
      if (toastEnabled) {
        $joszaki.toast({
          message: app.i18n.t(errorMessageKey),
          type: "error",
        });
      }
      if (err.userErrors) {
        message = err.userErrors // It is supposed to be an array
          .map((er) => er.message || er) // Sometimes the element is an object, sometimes its a string 😆
          .join(",");
        // TODO fix, sometimes this has custom error message in it from backend
        return Promise.reject(message);
      }
      return Promise.reject(err);
    }
  }

  async function query(query, variables = {}) {
    try {
      const requestHeaders = {
        authorization: `Bearer ${app.$cookie.get(USER_JWT_TOKEN_COOKIE_NAME)}`,
      };
      const result = await context.$graphql.default.request(
        query,
        variables,
        requestHeaders
      );
      return Promise.resolve(result);
    } catch (err) {
      reportResponseErrorsIfAny(err);

      const message = checkErrorCodes(err);
      if (message) {
        return Promise.reject(message);
      }
      return Promise.reject(err);
    }
  }

  inject("mutate", mutate);
  inject("query", query);
}
