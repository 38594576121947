import { useAuthStore } from "../store";

export function useLogout() {
  const authStore = useAuthStore();

  async function logoutFromOneSignal() {
    if (
      process.client &&
      window?.OneSignal &&
      typeof window?.OneSignal.logout === "function"
    ) {
      await window?.OneSignal.logout();
    }
  }
  return function logout() {
    authStore.logout();

    return logoutFromOneSignal();
  };
}
